interface UnitMetaData {
    [key: string]: {
        cost: number
        traits: string[]
    }
}

const unitMetaData: UnitMetaData = {
    "Ahri": { cost: 2, traits: ["Arcana", "Scholar"], },
    "Akali": { cost: 2, traits: ["Multistriker", "Warrior", "Pyro"], },
    "Ashe": { cost: 1, traits: ["Multistriker", "Eldritch"], },
    "Bard": { cost: 3, traits: ["Scholar", "Sugarcraft", "Preserver"], },
    "Briar": { cost: 5, traits: ["Shapeshifter", "Eldritch"], },
    "Blitz": { cost: 1, traits: ["Honeymancer", "Vanguard"], },
    "Camille": { cost: 5, traits: ["Chrono", "Multistriker"], },
    "Cassio": { cost: 2, traits: ["Witchcraft", "Incantor"], },
    "Diana": { cost: 5, traits: ["Bastion", "Frost"], },
    "Elise": { cost: 1, traits: ["Eldritch", "Shapeshifter"], },
    "Ezreal": { cost: 3, traits: ["Portal", "Blaster"], },
    "Fiora": { cost: 4, traits: ["Witchcraft", "Warrior"], },
    "Galio": { cost: 2, traits: ["Vanguard", "Portal", "Mage"], },
    "Gwen": { cost: 4, traits: ["Sugarcraft", "Warrior"], },
    "Hecarim": { cost: 3, traits: ["Multistriker", "Arcana", "Bastion"], },
    "Hwei": { cost: 3, traits: ["Blaster", "Frost"], },
    "Jax": { cost: 1, traits: ["Multistriker", "Chrono"], },
    "Jayce": { cost: 1, traits: ["Shapeshifter", "Portal"], },
    "Jinx": { cost: 1, traits: ["Sugarcraft", "Hunter"], },
    "Kalista": { cost: 4, traits: ["Faerie", "Multistriker"], },
    "Karma": { cost: 4, traits: ["Chrono", "Incantor"], },
    "Kassadin": { cost: 2, traits: ["Portal", "Multistriker"], },
    "Katarina": { cost: 3, traits: ["Faerie", "Warrior"], },
    "KogMaw": { cost: 2, traits: ["Honeymancer", "Hunter"], },
    "Lillia": { cost: 1, traits: ["Bastion", "Faerie"], },
    "Milio": { cost: 5, traits: ["Faerie", "Scholar"], },
    "Mord": { cost: 3, traits: ["Vanguard", "Eldritch"], },
    "Morgana": { cost: 5, traits: ["Witchcraft", "Preserver"], },
    "Nami": { cost: 4, traits: ["Mage", "Eldritch"], },
    "Nasus": { cost: 4, traits: ["Shapeshifter", "Pyro"], },
    "Neeko": { cost: 3, traits: ["Shapeshifter", "Witchcraft"], },
    "Nilah": { cost: 2, traits: ["Eldritch", "Warrior"], },
    "Nomsy": { cost: 1, traits: ["Dragon", "Hunter"], },
    "Norra": { cost: 5, traits: ["Portal", "Mage"], },
    "Nunu": { cost: 2, traits: ["Honeymancer", "Bastion"], },
    "Olaf": { cost: 4, traits: ["Hunter", "Frost"], },
    "Poppy": { cost: 1, traits: ["Bastion", "Witchcraft"], },
    "Rakan": { cost: 4, traits: ["Faerie", "Preserver"], },
    "Rumble": { cost: 2, traits: ["Sugarcraft", "Vanguard", "Blaster"], },
    "Ryze": { cost: 4, traits: ["Portal", "Scholar"], },
    "Seraphine": { cost: 1, traits: ["Faerie", "Mage"], },
    "Shen": { cost: 3, traits: ["Bastion", "Pyro"], },
    "Shyvana": { cost: 2, traits: ["Dragon", "Shapeshifter"], },
    "Smolder": { cost: 5, traits: ["Dragon", "Blaster"], },
    "Soraka": { cost: 1, traits: ["Sugarcraft", "Mage"], },
    "Swain": { cost: 3, traits: ["Shapeshifter", "Frost"], },
    "Syndra": { cost: 2, traits: ["Eldritch", "Incantor"], },
    "Tahm": { cost: 4, traits: ["Arcana", "Vanguard"], },
    "Taric": { cost: 4, traits: ["Bastion", "Portal"], },
    "Tristana": { cost: 2, traits: ["Blaster", "Faerie"], },
    "Twitch": { cost: 1, traits: ["Hunter", "Frost"], },
    "Varus": { cost: 4, traits: ["Blaster", "Pyro"], },
    "Veigar": { cost: 3, traits: ["Honeymancer", "Mage"], },
    "Vex": { cost: 3, traits: ["Chrono", "Mage"], },
    "Warwick": { cost: 1, traits: ["Frost", "Vanguard"], },
    "Wukong": { cost: 3, traits: ["Druid"], },
    "Xerath": { cost: 5, traits: ["Arcana"], },
    "Ziggs": { cost: 1, traits: ["Honeymancer", "Incantor"], },
    "Zilean": { cost: 2, traits: ["Chrono", "Frost", "Preserver"], },
    "Zoe": { cost: 1, traits: ["Portal", "Witchcraft", "Scholar"], },
}

export default unitMetaData;
